<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
========================================================================================== -->

<template>
  <vs-sidebar
    v-model="isSidebarActiveLocal"
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
        {{ Object.entries(this.data).length === 0 ? 'ADD NEW' : 'UPDATE' }}
        SUPPLIER
      </h4>
      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click.stop="isSidebarActiveLocal = false"
      />
    </div>
    <vs-divider class="mb-0" />

    <VuePerfectScrollbar
      :settings="settings"
      :key="$vs.rtl"
      class="scroll-area--data-list-add-new"
    >
      <div class="p-6">
        <vs-input
          v-validate="'required'"
          v-model="supplier_name"
          label="Supplier Name"
          class="mt-5 w-full"
          name="supplier-name"
        />
        <span
          v-show="errors.has('supplier-name')"
          class="text-danger text-sm"
          >{{ errors.first('supplier-name') }}</span
        >

        <vs-input
          v-validate="'required|numeric|min:10|max:10'"
          v-model="supplier_phone"
          label="Supplier Phone"
          class="mt-5 w-full"
          name="supplier-phone"
        />
        <span
          v-show="errors.has('supplier-phone')"
          class="text-danger text-sm"
          >{{ errors.first('supplier-phone') }}</span
        >

        <vs-input
          v-validate="'numeric|min:10|max:10'"
          v-model="supplier_alt_phone"
          label="Supplier Alt Phone"
          class="mt-5 w-full"
          name="supplier-alt-phone"
        />
        <span
          v-show="errors.has('supplier-alt-phone')"
          class="text-danger text-sm"
          >{{ errors.first('supplier-alt-phone') }}</span
        >

        <vs-textarea
          label="Supplier Address"
          class="mt-5 w-full"
          name="supplier-address"
        />
        <span
          v-show="errors.has('supplier-address')"
          class="text-danger text-sm"
          >{{ errors.first('supplier-address') }}</span
        >
      </div>
    </VuePerfectScrollbar>

    <div slot="footer" class="flex flex-wrap items-center p-6">
      <vs-button :disabled="!isFormValid" class="mr-6" @click="submitData"
        >Submit</vs-button
      >
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >Cancel</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect from 'vue-select';

export default {
  components: {
    VuePerfectScrollbar,
    'v-select': vSelect,
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: null,
      rev: null,
      supplier_name: '',
      supplier_address: '',
      supplier_alt_phone: '',
      supplier_phone: '',
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar');
          // this.$validator.reset()
          // this.initValues()
        }
      },
    },
    isFormValid() {
      return !this.errors.any() && this.supplier_name && this.supplier_phone;
    },
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let {
          _id,
          _rev,
          supplier_name,
          supplier_address,
          supplier_phone,
          supplier_alt_phone,
        } = JSON.parse(JSON.stringify(this.data));
        this.id = _id;
        this.rev = _rev;
        this.supplier_name = supplier_name;
        this.supplier_address = supplier_address;
        this.supplier_phone = supplier_phone;
        this.supplier_alt_phone = supplier_alt_phone;
        this.initValues();
      }
    },
  },
  methods: {
    initValues() {
      if (this.data._id) return;
      this.id = null;
      this.supplier_name = '';
      this.supplier_address = '';
      this.supplier_phone = '';
      this.supplier_alt_phone = '';
    },
    submitData() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.notify({
            color: 'warning',
            title: 'Processing',
            text: 'Processing Data',
          });
          const obj = {
            _id: this.id,
            _rev: this.rev,
            supplier_name: this.supplier_name,
            supplier_address: this.supplier_address,
            supplier_phone: this.supplier_phone,
            supplier_alt_phone: this.supplier_alt_phone,
          };

          if (this.id !== null && this.rev !== null) {
            this.$store
              .dispatch('suppliers/updateSupplier', obj)
              .then(() => {
                this.$vs.notify({
                  color: 'success',
                  title: 'Supplier Updated Successfully',
                  text: 'You have successfully updated a supplier',
                });
                this.$emit('closeSidebar');
                this.initValues();
              })
              .catch((err) => {
                console.error(err);
                this.$vs.notify({
                  color: 'danger',
                  title: 'Error',
                  text: err,
                });
              });
          } else {
            delete obj._id;
            delete obj._rev;
            this.$store
              .dispatch('suppliers/addSupplier', obj)
              .then(() => {
                this.$vs.notify({
                  color: 'success',
                  title: 'Supplier Added Successfully',
                  text: 'You have successfully added a new supplier',
                });
                this.$emit('closeSidebar');
                this.initValues();
              })
              .catch((err) => {
                console.error(err);
                this.$vs.notify({
                  color: 'danger',
                  title: 'Error',
                  text: err,
                });
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
